import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import SEO from "../components/seo"
import Button from "../components/button"
import Shortcut from "../components/shortcut"
import ShortcutList from "../components/shortcut-list"

import { navigate } from "gatsby"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allFile.edges

    return (
      <div>
        <SEO title="All posts" />
        <Link to="/">
          <Button marginTop="85px">&lt; Home</Button>
        </Link>
        <br />
        <Bio />
        <div style={{ margin: "20px 0 40px" }}>
          <ShortcutList>
            {posts.map(({ node }) => {
              const title =
                node.childMdx.frontmatter.title || node.childMdx.fields.slug
              return (
                <Shortcut
                  key={node.childMdx.fields.slug}
                  onClick={() => {
                    navigate(`blog${node.childMdx.fields.slug}`)
                  }}
                  title={title}
                ></Shortcut>
              )
            })}
          </ShortcutList>
        </div>
      </div>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "blog" } }
      sort: { fields: [childMdx___frontmatter___date], order: DESC }
      limit: 10
      skip: 0
    ) {
      edges {
        node {
          childMdx {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
            }
          }
        }
      }
    }
  }
`
